import React from 'react'
import Styled from 'styled-components'

import H1 from './H1'
import H2 from './H2'

import theme from '../theme'

import AndroidSrc from '../assets/google-play-fr.png'
import IosSrc from '../assets/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.png'

import HideDesktop from './HideDesktop'
import HideMobile from './HideMobile'

import PlayerDesktopImageSrc from '../assets/player-desktop-background.jpg'
import PromoCodeImageSrc from '../assets/promo-code.png'
import PromoCodeMobileImageSrc from '../assets/promo-code-mobile.png'


import {
  IOS_LINK,
  ANDROID_LINK,
} from '../constant'

const PlayerHeroSection = Styled.section`
  width: 100%;
  min-height: 500px;
  max-height: 900px;
  padding-top: 35px;
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-color: #fcefe3;
  background-image: url(${PlayerDesktopImageSrc});
  padding-bottom: ${(props) => props.darkTheme ? 150 : 0}px;

  @media (min-width: 1400px) {
    min-height: 600px;
    max-height: 1200px;
  }
  
  @media (max-width: ${theme.breakpoint}px) {
    min-height: 300px;
    background-image: none;
  }
`

const PlayerHeroSectionContent = Styled.div`
  text-align: center;
  position: relative;
  bottom: -250px;

  @media (min-width: 1400px) {
    bottom: -350px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    bottom: 0px;
  }
`

const PlayerSection = Styled.div`
  background-color: white;
  margin: auto;
  text-align: center;
  padding-top:15px;
  padding-bottom: 80px;
  display: inline-flex;
  width: 100%;

  @media (max-width: ${theme.breakpoint}px) {
    padding-top:30px;
    padding-bottom: 50px;
    display: block;
  }
`

const PlayerLayout = Styled.div`
  max-width: ${theme.grid.fullWidth}px;
  width: 40%;
  text-align: center;
  margin: auto;

  @media (max-width: ${theme.breakpoint}px) {
    width: 90%;
    display: inline-flex;
  }
`

const PromoLayout = Styled.div`
  max-width: ${theme.grid.fullWidth}px;
  width: 55%;
  display: inline;
  text-align: center;
  margin: auto;

  @media (max-width: ${theme.breakpoint}px) {
    width: 60%;
    padding-top:10px;
    padding-bottom: 10px;
    display: inline-flex;
  }

  @media (max-width: 500px) {
    width: 90%;
    padding-top:10px;
    padding-bottom: 10px;
  }
`

const PromoImg = Styled.img`
  width: 100%;
`

const PlayerHeroTitle = Styled.div`
  text-align:center;
`

const H2Overrided = Styled(H2)`
  font-size: 50px;
  font-family: ${theme.typography.gascogne};
  margin: auto;
  max-width:500px;
  text-align:center;

  @media (min-width: ${theme.grid.fullWidth}px) {
    max-width:600px;
    font-size: 60px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 32px;
  }
`

const Img = Styled.img`
  ${(props) => props.imgStyle}
  @media (max-width: ${theme.breakpoint}px) {
    max-height:400px;
    max-width:100%;
  }
`

const Footer = Styled.footer`
  z-index: 2;
  margin-top: -50px;
  position: relative;
  border-radius: ${theme.radius.big}px ${theme.radius.big}px 0 0;
  background-color: ${theme.color.secondary};
  padding-bottom:10px;
`

const FooterContent = Styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  max-width: ${theme.grid.fullWidth}px;
`

const FooterDownload = Styled.div`
  margin: auto;
  max-width: 400px;
  text-align: center;
`

const DownloadImgLink = Styled.a`
  width: 214px;
  display: inline;
  cursor: pointer;
  margin: 5px;
  @media (max-width: ${theme.breakpoint}px) {
    width: 160px;
  }
`

const DownloadImg = Styled.img`
  width: 45%;
`

const FooterTitle = Styled.h4`
  font-size: 14px;
  line-height: 1.5;
  margin: 23px 20px 43px 20px;
  color: ${theme.color.alternativeWhite};
  font-family: ${theme.typography.circular};
  text-transform: uppercase;
  text-align: center;
`

const FooterLinks = Styled.div`
    text-align: center;
    margin-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 1.49;
    color: ${theme.color.alternativeWhite} !important;
`

const FooterLink = Styled.a`
    font-size: 14px;
    line-height: 1.49;
    display: inline;
`

const ios = <DownloadImgLink target="_blank" href={IOS_LINK}><DownloadImg src={IosSrc} /></DownloadImgLink>
const android = <DownloadImgLink target="_blank" href={ANDROID_LINK}><DownloadImg src={AndroidSrc} /></DownloadImgLink>

const Player = ({
  mobileImgSrc,
  title,
  subtitle,
  imgStyle,
  imgStyleMobile,
}) => (
  <div>
    <PlayerHeroSection>
      <PlayerHeroSectionContent>
        <PlayerHeroTitle>
          <H2Overrided>{title}</H2Overrided>
        </PlayerHeroTitle>
        <HideDesktop>
          {mobileImgSrc && <Img src={mobileImgSrc} alt={title} imgStyle={imgStyle} imgStyleMobile={imgStyleMobile} />}
        </HideDesktop>
      </PlayerHeroSectionContent>
    </PlayerHeroSection>
    <PlayerSection>
      <PlayerLayout>
        <iframe width="100%" height="126" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/783971992%3Fsecret_token%3Ds-5QmI16I6ymA&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
      </PlayerLayout>
      <PromoLayout>
        <HideDesktop>
          <a href="https://gift.mind-app.io/">
            <PromoImg src={PromoCodeMobileImageSrc} />
          </a>
        </HideDesktop>
        <HideMobile>
          <a href="https://gift.mind-app.io/">
            <PromoImg src={PromoCodeImageSrc} />
          </a>
        </HideMobile>
      </PromoLayout>
    </PlayerSection>
    <Footer>
      <FooterContent>
        <FooterTitle>{subtitle}</FooterTitle>
        <FooterDownload>
          {ios}
          {android}
        </FooterDownload>
        <FooterLinks>
          <FooterLink>Terms & Conditions</FooterLink> / <FooterLink>Privacy</FooterLink> @ Mind 2019
        </FooterLinks>
      </FooterContent>
    </Footer>
  </div>
)

Player.defaultProps = {

}


export default Player
