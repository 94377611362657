import React, { PureComponent, createRef } from 'react'

import Player from '../components/Player'
import Layout from '../layouts/MainLayout'

import PlayerMobileImageSrc from '../assets/player-image.png'

class PlayerPage extends PureComponent {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props - Properties of the PureComponent
   */
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    this.hideFooter = true
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  /* METHODS */

  toggleSeeMore = () => this.setState({ seeMore: !this.state.seeMore })

  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const { location } = this.props
    const {} = this.state

    return (
      <Layout location={location} hideFooter>
        <Player
          mobileImgSrc={PlayerMobileImageSrc}
          title="Votre première séance de méditation"
          subtitle="Retrouvez d'autres séances gratuites sur l'application Mind"
        />
      </Layout>
    )
  }
}

export default PlayerPage
